// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-apollo-core-src-pages-404-js": () => import("./../../../../gatsby-theme-apollo-core/src/pages/404.js" /* webpackChunkName: "component---gatsby-theme-apollo-core-src-pages-404-js" */),
  "component---gatsby-theme-apollo-docs-src-components-template-js": () => import("./../../../../gatsby-theme-apollo-docs/src/components/template.js" /* webpackChunkName: "component---gatsby-theme-apollo-docs-src-components-template-js" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-license-mdx": () => import("./../../../src/pages/license.mdx" /* webpackChunkName: "component---src-pages-license-mdx" */)
}

